<template>
  <div>
      <v-row
        v-for="index in searchCount"
        :key="index"
        style="height:50px;padding:0px 10px;"
      >
        <v-col
          cols="4"
          sm="4"
          md="4"
          lg="4"
        >
          <v-select
            v-model="searchKeyArr[index-1]"
            :items="searchList"
            dense
            outlined
            class="text-overline text-lg-subtitle-1"
            style="font-size: 10px;"
            :label="'검색조건[' + index + ']'"
            @change="selectChangeHandler"
          />
        </v-col>
        <v-col
          cols="8"
          sm="8"
          md="8"
          lg="8"
          class="text-right"
          style="padding-right:20px;padding-left:0px;"
        >
          <div style="display: flex">
             <template v-if="searchKeyArr[index-1] == 'mtaxRegYn'">
                <v-select
                  v-model="searchValArr[index-1]"
                  :items="[
                    { text: '전체', value: '' },
                    { text: '가입', value: 'Y' },
                    { text: '미가입', value: 'N' },
                  ]"
                  dense
                  outlined
                />
             </template>
             <template v-else>
               <v-text-field
                 v-model="searchValArr[index-1]"
                 outlined
                 dense
               />
             </template>

              <template v-if="index == 1">
                <v-btn style="height:40px; width:40px; margin-left:10px;" outlined icon @click="searchAddHandler" v-bind:disabled="searchKeyArr[index-1] == 'all' ? true : false">
                  <span class="mdi mdi-plus"></span>
                </v-btn>
              </template>
              <template v-else>
                <v-btn style="height:40px; width:40px; margin-left:10px;" outlined icon @click="searchAddHandlerDelete">
                  <span class="mdi mdi-minus"></span>
                </v-btn>
              </template>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          style="padding:0 20px; margin-top:25px; margin-bottom:20px;">
          <v-btn
            color="warning"
            class="rounded-pill mr-5"
            depressed
            block
            @click="searchEmitHandler"
          >
            조회
          </v-btn>

        </v-col>
      </v-row>
  </div>
</template>

<script>
  export default {
    name: 'SearchAdd',
    props: {
      searchList: {
        type: Array,
      },
    },
    data: () => ({
      searchCount: 1,
      searchKeyArr: [],
      searchValArr: [],
    }),
    methods: {
      searchAddHandler () {
        if (this.searchList.length > this.searchCount) this.searchCount++
      },

      searchAddHandlerDelete () {
        this.searchCount--;
      },

      selectChangeHandler () {
        this.searchList = this.searchList.map(item => {
          item.disabled = false;
          return item
        })
      },
      searchEmitHandler () {
        const retArr = []
        for (let i = 0; i < this.searchKeyArr.length; i++) {
          const obj = { key: null, value: '' }
          obj.key = this.searchKeyArr[i]
          obj.value = this.searchValArr[i]
          retArr.push(obj)
        }
        this.$emit('searchFormEvent', retArr)
      },
    },
  }
</script>

<style>
  .search_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-input__control .v-input__slot {
    min-width:80px !important;
  }
</style>
