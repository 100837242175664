<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    metaInfo () {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: '비즈페이 / 대리점전산',
        // all titles will be injected into this template

        meta: [
          { name:'title', content:'비즈페이 / 대리점전산' },
          { name: 'google', content: 'notranslate'}

        ]
      }
    },
    created () {
      const mq = window.matchMedia('(prefers-color-scheme: dark)')
      mq.addEventListener('change', e => {
        this.$vuetify.theme.dark = e.matches
      })
      this.getRouterParams()

      const meta = document.createElement('meta')
      meta.name = 'google'
      meta.content = 'notranslate'
      document.getElementsByTagName('head')[0].appendChild(meta)
    },
    methods: {
      getRouterParams () {
        // console.log(this.$router.currentRoute.query);
      },
    },
  }
</script>
