import axios from "axios";
import { baseUrl } from "@/store/CONSTANT";

export default {
  state: {
    loggedInUser: {
      dealerId: "", //'B000241',
      dealerKind: 0, //34,
      dealerName: "", //'이성규알리바바지사',
      dealerBizCode: "", //'0000561',
    },
    loading: false,
    error: null,
    selectedMenu: "거래조회",
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    loading: (state) => state.loading,
    error: (state) => state.error,
    selectedMenu: (state) => state.selectedMenu,
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      // this.$router.go("/");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
    setSelectedMenu(state, val) {
      state.selectedMenu = val;
    },
    clearUser(status) {
      status.loggedInUser = {
        dealerId: "",
        dealerKind: 0,
        dealerName: "",
        dealerBizCode: "",
      };
    },
  },
  actions: {
    dealerLogin({ commit }, payLoad) {
      // dotenv 를 이용하려 했으나 그개념을 이해 못해 벌어질 일들이 우려되어 고전방식으로 변경함
      // 몇가지 세버 세션관련 기술이 있었지만 모두 삭제함. 상당한 스터디와 이해가 필요하고 애해못하고 이용할경우 향후 큰문제가 될것이 우려되어 모두 삭제함
      // 기본 원래 개념은 서버리스 였었다고 해야하나...... 상당한 규모의 기능삭제가 있었다.
      commit("setLoading", true);
      return axios({
        method: "post",
        url: baseUrl + "/agency/login",
        data: payLoad,
      })
        .then((res) => {
          return commit("setUser", res.data);
        })
        .catch((error) => {
          console.log(error);
          throw error.response.data.message;
        })
        .finally((_) => {
          commit("clearError");
          //  commit('toggleThemeLoadingState', false)
        });
    },
  },
};
